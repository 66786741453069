// Dynamic Margin & Padding class:
$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25rem;

    /* margin #{$margin} */
    .m#{$i} {
      margin: $margin;
    }

    .ml#{$i} {
      margin-left: $margin;
    }

    .mr#{$i} {
      margin-right: $margin;
    }

    .mt#{$i} {
      margin-top: $margin;
    }

    .mb#{$i} {
      margin-bottom: $margin;
    }

    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }

    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}

@include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;

    /* padding #{$padding} */
    .p#{$i} {
      padding: $padding;
    }

    .pl#{$i} {
      padding-left: $padding;
    }

    .pr#{$i} {
      padding-right: $padding;
    }

    .pt#{$i} {
      padding-top: $padding;
    }

    .pb#{$i} {
      padding-bottom: $padding;
    }

    .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }

    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}

.logo {
  background-color: #fff;
}
.logo img {
  max-height: 100px;
}
.ant-layout-header {
  background-color: #234d6d;
}

.text-right{
  text-align: right;
}

app-sidebar-layout,
app-no-sidebar-layout {
  width: 100%;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f15637;
}

.topheadingifbutton {
  position: relative;
  margin-bottom: 20px;
  clear: both;
  overflow: hidden;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 15px;
  h3 {
    float: left;
    font-size: 22px;
    letter-spacing: 0.2px;
  }

  .rightbutton {
    @media only screen and (min-width: 781px) {
      float: right;
    }
    color: #fff;
    background-color: #134064;
    border: 1px solid #306793;
    cursor: pointer;
    padding: 3px 10px;
  }
}
.exportFile {
  @media only screen and (max-width: 780px) {
    margin-bottom: 10px;
    span {
      display: none;
    }
    display: flex;
  }
}

.submitButton {
  @media only screen and (max-width: 780px) {
    text-align: center;
    button {
      width: 100%;
    }
  }
  button {
    float: right;
  }
}

.action-button {
  margin-right: 4px;
}

.active {
  background-color: green;
}
.inactive {
  background-color: red;
}
.status {
  width: 12px;
  height: 12px;
  display: block;
  // float: right;
  margin-left: 5px;
}
.formlabel label {
  font-weight: 500;
}

.ant-btn-primary {
  background: #214a6d;
  border-color: #2f618b;
}

.saveButton {
  text-align: right;
}

.width100{
  width: 100%;
}

.text-right{
  text-align: right;
}

.ant-select-item-option-content {
  flex: auto;
  overflow: auto;
  white-space: inherit;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.space-between{
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .right-layout-full {
    min-width: 100vw;
  }
  body {
    overflow-x: hidden;
  }
  .rowBlockTbl {
    table {
      width: 100% !important;
      table-layout: auto !important;
    }
    tr:first-child {
      display: none;
    }
    tr {
      width: 100%;
      td:first-child {
        border-top: 2px solid #fafafa;
      }
    }
    td {
      display: block;
      width: 100% !important;
    }
    td:before {
      content: attr(data-name);
      display: block;
      color: #c82808;
      margin-bottom: 5px;
      font-weight: 500;
    }
  }
}
